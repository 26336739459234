<template>
  <div class="pa-5 pl-10">
    <div class="d-flex align-center mb-3">
      <v-btn icon>
        <v-icon @click="back" color="primary">mdi-chevron-left</v-icon>
      </v-btn>
      <h3 class="text-h4 font-weight-bold" color="primary">{{ title }}</h3>
    </div>
    <p class="text-body-1 text--secondary mb-3">
      {{ receivedTime | getDateTime }} - Tags: {{ tags }}
    </p>
    <p class="text-h5">{{ context }}</p>
    <p class="text-body-1" v-html="description"></p>
  </div>
</template>
<script>
import configDate from '@/mixins/configDate'
import { announcement } from '@/api/announcement'

export default {
  mixins: [configDate],
  data() {
    return {
      title: '',
      context: '',
      description: '',
      tags: '',
      receivedTime: '',
      listTags: [],
    }
  },
  created() {
    this.getCategoryList()
  },
  computed: {
    notiPayload() {
      return this.$store.getters.getNotiPayload
    },
  },
  watch: {
    notiPayload: {
      handler(newVal) {
        // Handle the change in notiPayload
        if (Object.keys(newVal).length > 0) this.getAnnouncementDetail()
      },
      immediate: true, // Trigger the handler immediately when the component is created
    },
  },
  methods: {
    async getCategoryList() {
      await announcement
        .getAllTags()
        .then(res => {
          this.listTags = res
        })
        .catch(() => {
          this.listTags = []
        })
    },
    async getAnnouncementDetail() {
      const payload = this.$store.getters.getNotiPayload
      try {
        const res = await announcement.getAnnounByID(payload.announcementID)
        this.title = res.title
        this.context = res.context
        this.description = res.description
        this.tags =
          res.tagIDs.length > 0
            ? this.listTags
                .filter(el => res.tagIDs.includes(el.anouncementTagID))
                .map(el => el.anouncementTagName)
                .join(', ')
            : ''
        this.receivedTime = res.startTime
      } catch (error) {
        console.error('Error fetching announcement details:', error)
      } finally {
        this.$store.commit('setNotiPayload', {})
      }
    },

    back() {
      window.history.back()
    },
  },
}
</script>
